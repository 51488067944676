<template>
    <div class="con">
        <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
        <div class="right">
            <div class="switch">
                <div class="switch-item">
                    <span class="mg-rt50">打印机名称</span>
                    <input v-model="deviceModel" class="switchinput" placeholder="请输入打印机名称" />
                </div>
                <div class="switch-item" :class="{'devicetype-switch-item': id && bind_status == 0}">
                    <span>打印机类型</span>
                    <van-radio-group v-model="deviceType" direction="horizontal" @change="changeDeviceType">
                        <van-radio name="7" icon-size="3rem" v-if="isApp == 'android' || isApp == 'mt_android'">USB打印机(安卓)</van-radio>
                        <van-radio name="12" icon-size="3rem" v-if="isApp == 'android' || isApp == 'mt_android'">蓝牙打印机(安卓)</van-radio>
                        <van-radio name="8" icon-size="3rem" v-if="isApp == 'windows'">驱动打印机(Windows)</van-radio>
                        <van-radio name="9" icon-size="3rem" v-if="isApp == 'ios'">蓝牙打印机(IOS)</van-radio>
                        <van-radio name="11" icon-size="3rem">佳博云打印机(通用)</van-radio>
                    </van-radio-group>
                </div>
                
                <div class="switch-item printset-switch-item" v-if="deviceType == '7'">
                    <span>选择打印机</span>
                    <van-radio-group v-model="printer" @change="changePrinter" direction="horizontal">
                        <van-radio v-for="(item, index) in labelDeviceList" :key="index" :name="index" icon-size="3rem" class="printset">
                            <span>{{ item.deviceProductName }}</span>
                        </van-radio>
                    </van-radio-group>
                </div>

                <div class="switch-item printset-switch-item" v-if="deviceType == '12'">
                    <span>选择打印机</span>
                    <van-radio-group v-model="printer" direction="horizontal">
                        <van-radio v-for="(item, index) in labelDeviceList" :key="index" :name="index" icon-size="3rem" class="printset" @click="changePrinter(index)">
                            <span>{{ item.deviceName }}</span>
                        </van-radio>
                    </van-radio-group>
                </div>
                
                <div class="switch-item printset-switch-item" v-if="deviceType == '8'">
                    <span>选择打印机</span>
                    <van-radio-group v-model="printer_name" @change="changePrinterOfWin" direction="horizontal">
                        <van-radio v-for="(item, index) in labelPrintersOfWin" :key="index" :name="item" icon-size="3rem" class="printset">
                            <span>{{ item }}</span>
                        </van-radio>
                    </van-radio-group>
                </div>

                <div class="switch-item printset-switch-item" v-if="deviceType == '9'">
                    <span>选择打印机</span>
                    <van-radio-group v-model="printer" @change="changePrinterOfIOS" direction="horizontal" :disabled="isDisabled">
                        <van-radio v-for="(item, index) in bluetoothDeviceIos" :key="index" :name="index" icon-size="3rem" class="printset">
                            <span>{{ item }}</span>
                        </van-radio>
                    </van-radio-group>
                </div>

                <template v-if="deviceType == '11'">
                    <div class="switch-item">
                        <span>打印机编号</span>
                        <input v-model="deviceSN" class="switchinput" placeholder="请输入打印机编号" :disabled="id" />
                    </div>
                </template>

                <div class="switch-item">
                    <span>打印纸尺寸</span>
                    <van-radio-group v-model="deviceSize" direction="horizontal">
                        <!-- 1=50X40,2=40X30 -->
                        <van-radio name="1" icon-size="3rem" class="mg-rt40">50mm*40mm</van-radio>
                        <van-radio name="2" icon-size="3rem">40mm*30mm</van-radio>
                    </van-radio-group>
                </div>

                <div class="spt-btns">
                    <button v-if="id" class="printtest-btn bg-3DB956 mg-rt70 color-fff" @click="print">测试打印</button>
                    <button v-if="!id" class="printtest-btn mg-rt70 bg-EAEAEA color-666" @click="openDialog('goback')">取消</button>
                    <button v-if="id" class="printtest-btn mg-rt70 bg-e60012 color-fff" @click="openDialog('delete')">删除</button>
                    <button class="printtest-btn bg-1588F5 mg-rt70 color-fff" @click="save">保存</button>
                </div>
            </div>
        </div>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            @closePopDialog="closePopDialog"
            @confirmPopDialog="confirmPopDialog"
        ></popupOfDialog>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import titlebar from "@/views/component/titlebar";
import { formatTime, getPrinterType } from '../../utils/util';
import { getAllPrinters } from '../../utils/win-print';
import { bleConnect, checkTagConnect, connectBluTagPrint, connectLabel, searchBleConnect, searchDevice } from '../../utils/outPrint';
export default {
    name: "setPrintType",
    components: {
        titlebar,
        popupOfDialog,
    },
    data() {
        return {
            deviceType: '', // 打印机类型
            deviceModel: '', // 打印机名称
            print_data: {
                type: 5,
                good_list: [{
                    name: '测试商品',
                    fact_price: '100.00',
                    dw: '个',
                    produce_time: formatTime(new Date(), 'yyyy年MM月dd日'),
                    quality: '60',
                    bar_code: '5231456856321',
                    bar_code_img: '',
                    type: 5,
                    printType: 0, // 打印类型， 0 为普通 1为预包装
                }]
            },
            id: null,
            deviceIP: '',
            deviceSize: '',
            showDialog: false,
            dialogTitle: '',
            dialogMsg: '',
            dialogType: '',
            dialogData: {},
            isApp: '', // 是否是在APP内
            printer: -1,
            printer_name: '',
            printer_address: '',
            bind_status: '',
            printer_name_show: '',
            printer_address_show: '',
            IpErrorMsg: '',
            receiveMiniOrder: true, // 是否接收小程序订单
            deviceSN: '',
            deviceKey: '',
            page_name: '',
            labelPrintersOfWin: [],
            isDisabled: false,
        }
    },
    computed: {
        ...mapGetters({ shop: 'shop', labelDeviceList: 'labelDeviceList', bluetoothDeviceIos: 'bluetoothDeviceIos' }),
    },
    methods: {
        changePrinterOfWin(e) {
            // console.log(e);
            this.printer_name = e;
        },
        async changeDeviceType(e) {
            this.deviceType = e;
            if (e == '7') {
                await searchDevice('usb', 3);
                if (this.labelDeviceList.length < 1) {
                    this.$toast('未查找到设备');
                }
                console.log('usb 打印设备 labelDeviceList', this.labelDeviceList);
            } else if (e == '8' && this.isApp == 'windows') {
                this.labelPrintersOfWin = getAllPrinters();
                // printPreview(this.driverPrinters[0])
                // console.log('labelPrintersOfWin', this.labelPrintersOfWin);
            } else if (e == '12') {
                await searchDevice('bluetooth', 3);
                if (this.labelDeviceList.length < 1) {
                    this.$toast('未查找到设备');
                }
                console.log('bluetooth 打印设备：labelDeviceList', this.labelDeviceList);
            } else if (e == '9') { // iOS蓝牙
                await searchBleConnect();
                if (this.bluetoothDeviceIos.length < 1) {
                    this.$toast('未查找到设备');
                }
            }
        },
        changePrinterOfIOS(e) {
            this.printer = e;
            // console.log('this.printer', this.printer);
            if (this.bluetoothDeviceIos.length > 0 && this.printer > -1) {
                this.printer_name = this.bluetoothDeviceIos[this.printer];
                console.log('this.printer_name', this.printer_name);
                bleConnect(this.printer);
            }
        },
        // 安卓连接标签机
        async changePrinter(e) {
            this.printer = e;
            // console.log(this.printer, 'this.printer');
            if (this.deviceType == '7') {
                if (this.labelDeviceList.length > 0 && this.printer > -1) {
                    this.printer_name = this.labelDeviceList[this.printer].deviceProductName;
                    this.printer_address = this.labelDeviceList[this.printer].deviceName;
                    connectLabel(this.printer_name, this.printer_address);
                }
            } else if (this.deviceType == '12') {
                this.printer_name = this.labelDeviceList[this.printer].deviceName;
                this.printer_address = this.labelDeviceList[this.printer].address;
                connectBluTagPrint(this.printer_name, this.printer_address);
            }
        },
        confirmPopDialog(data) {
            if (data.type == 'delete') {
                this.deletePrint();
            } else if (data.type == 'goback') {
                this.goBack();
            }
            this.closePopDialog();
        },
        closePopDialog(data) {
            this.showDialog = false;
        },
        openDialog(type) {
            this.dialogType = type;
            if (type == 'delete') {
                this.dialogTitle = '删除标签打印机';
                this.dialogMsg = `确认删除此打印机吗？`;
            } else if (type == 'goback') {
                this.dialogTitle = '返回';
                this.dialogMsg = `系统不会保存您的更改，确认返回吗？`;
            }
            this.showDialog = true;
        },
        deletePrint() {
            let params = {
                ids: this.id,
                cloud_sn: this.deviceSN,
            }
            this.$api.dt_deletePrinter(params).then(res => {
                if (res.code == 1) {
                    this.goBack();
                    this.$toast('删除打印机成功，门店设置已更新')
                    if (this.isApp == 'ios') {
                        deviceConnectClose();
                    }
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        goBack() {
            this.$router.replace({
                name: 'featureSet',
                query: {
                    name: 'featureSet',
                    active: this.$route.query.active,
                },
            })
        },
        setData() {
            this.$api.dt_getPrinterInfo({
                id: this.id
            }).then(async res => {
                if (res.code == 1) {
                    this.info = res.data;
                    this.deviceModel = res.data.name;
                    this.deviceType = getPrinterType(res.data.printer_type, 1, 3);
                    this.deviceSize = res.data.size.toString() || '';
                    
                    this.deviceSN = res.data.cloud_sn || '';

                    this.printer_name = res.data.printer_name;
                    this.printer_address = res.data.printer_address;
                    if (this.deviceType == '7') { // USB
                        await searchDevice('usb', 3);
                        if (this.labelDeviceList.length > 0) {
                            const index = this.labelDeviceList.findIndex(e => (e.deviceProductName == res.data.printer_name && e.deviceName == res.data.printer_address));
                            if (index > -1) {
                                this.printer = index;
                            }
                        } else {
                            this.$toast('未查找到设备');
                        }
                    } else if (this.deviceType == '9') { // 蓝牙
                        await searchBleConnect();
                        if (this.bluetoothDeviceIos.length > 0) {
                            const index = this.bluetoothDeviceIos.findIndex(e => (e == res.data.printer_name));
                            if (index > -1) {
                                this.printer = index;
                            }
                        } else {
                            this.$toast('未查找到设备');
                        }
                    } else if (this.deviceType == '8' && this.isApp == 'windows') { // win驱动
                        this.labelPrintersOfWin = getAllPrinters();
                        if (this.labelPrintersOfWin.length < 0) {
                            this.$toast('未查找到设备');
                        }
                    } else if (this.deviceType == '12') { // 安卓蓝牙
                        await searchDevice('bluetooth', 3);
                        if (this.labelDeviceList.length > 0) {
                            const index = this.labelDeviceList.findIndex(e => (e.deviceName == res.data.printer_name && e.address == res.data.printer_address));
                            if (index > -1) {
                                this.printer = index;
                            }
                        } else {
                            this.$toast('未查找到设备');
                        }
                    }
                } else {
                    this.$toast(res.msg);
                }
            })
        },
        async save() {
            if (this.deviceModel == '') {
                this.$toast('请输入打印机名称')
                return
            }
            if (this.deviceType == '') {
                this.$toast('请选择打印机类型')
                return
            }
            if (this.deviceType == '7' && (this.printer_name == '' || this.printer_address == '')) {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '8' && this.printer_name == '') {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '9' && this.printer_name == '') {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '12' && this.printer_name == '') {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '11' && this.deviceSN == '') {
                this.$toast('请输入打印机编号')
                return
            }
            if (this.deviceSize == 0 || !this.deviceSize || this.deviceSize == '') {
                this.$toast('请选择打印纸尺寸');
                return;
            }
            if (this.deviceType == '7') {
                const tagR = await checkTagConnect();
                console.log('tagR', tagR);
                if (tagR.code != 1) {
                    this.printer = -1;
                    return;
                }
            }
            // console.log('this.checkedFoodList', this.checkedFoodList);
            let params = {
                name: this.deviceModel,
                printer_type: getPrinterType(this.deviceType, 2, 3),
                status: 1, // 1,正常连接 2，连接异常
                type: 3, // 类型 1，前台打印机  2，后厨打印机 3标签打印机
                size: this.deviceSize,
            }
            if (this.isApp == 'android' || this.isApp == 'mt_android') {
                params.printer_name = this.printer_name;
                params.printer_address = this.printer_address;
            }
            if (this.isApp == 'windows') {
                params.printer_name = this.printer_name;
            }
            if (this.isApp == 'ios') {
                params.printer_name = this.printer_name;
            }
            if (this.deviceType == '11') {
                params.cloud_sn = this.deviceSN;
            }

            if (this.id) {
                params.id = this.id;
                this.$api.dt_editPrinter(params).then(res => {
                    if (res.code == 1) {
                        this.goBack();
                        this.$toast('编辑打印机成功，门店设置已更新')
                    } else {
                        this.$toast(res.msg);
                    }
                })
            } else {
                this.$api.dt_addPrinter(params).then(async res => {
                    if (res.code == 1) {
                        this.goBack();
                        this.$toast('新增打印机成功，门店设置已更新')
                    } else {
                        this.$toast(res.msg);
                    }
                })
            }
        },
        // 测试打印
        async print() {
            if (this.deviceType == '') {
                this.$toast('请选择打印机类型')
                return
            }
            if (this.deviceType == '7' && (this.printer_name == '' || this.printer_address == '')) {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '8' && this.printer_name == '') {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '9' && this.printer_name == '') {
                this.$toast('请选择打印机')
                return
            }
            if (this.deviceType == '11' && this.deviceSN == '') {
                this.$toast('请输入打印机编号')
                return
            }
            if (this.deviceSize == 0 || !this.deviceSize || this.deviceSize == '') {
                this.$toast('请选择打印纸尺寸');
                return;
            }
            if (this.deviceType == '7') {
                const tagR = await checkTagConnect();
                if (tagR.code != 1) {
                    this.printer = -1;
                    return;
                }
            }
            this.$printFunc.printLabelReceipt(this.print_data);
        },
    },
    async created() {
        this.page_name = this.$route.query.name || '';
        console.log(this.shop);
        this.isApp = localStorage.nowDevice || '';
        this.id = this.$route.query.id || null;
        this.deviceSize = '';
        if (this.id) {
            this.setData();
            if (this.isApp == 'ios') {
                this.isDisabled = true;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.switchinput:disabled {
    background: #fff;
    color: #c8c9cc;
    opacity: 1;
    -webkit-text-fill-color: #c8c9cc;
}
.printset-switch-item {
    min-height: 9.5rem;
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    .printset {
        line-height: 2;
        margin-bottom: 2rem;
    }
}
.devicetype-switch-item {
    min-height: 9.5rem;
    height: auto !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    /deep/.van-radio-group {
        width: 75%;
        // justify-content: flex-end;

    }
    /deep/.van-radio {
        height: 4rem;
        line-height: 2;
        margin-bottom: 1.5rem;
    }
}
.printtest-btn {
    display: block;
    font-size: 2.4rem;
    width: 30rem;
    height: 7rem;
    border-radius: 0.8rem;
    // color: #fff;
    &:active {
        background: #cf1d2c;
    }
}

.reconnect-btn {
    width: 4rem;
    height: 1rem;
}
.con {
    height: 100%;
}

.right {
    margin-top: 1.5rem;
    height: calc(100% - 10.6rem);
    overflow-y: scroll;
    overflow-x: hidden;

    .switch {
        // margin-top: 0.2rem;
        background: #fff;
        padding-bottom: 2rem;

        .switch-item {
            font-size: 2.4rem;
            height: 9.5rem;
            background-color: #fff;
            // margin-top: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.2rem;
            border-bottom: 0.15rem solid #eeeeee;
            width: calc(100% - 16.8rem);
            margin: 0 4rem;
        }

        .switch-item:last-child {
            border-bottom: none !important;
        }

        .spt-btns {
            display: flex;
            margin: 5rem auto 2.5rem;
            justify-content: center;
        }

        .switchinput {
            // border: 0.01rem #eeeeee solid;
            border-radius: 0.8rem;
            height: 70%;
            width: 80%;
        }

        /deep/.van-radio-group {
            height: 100%;
            width: 80%;
        }

        .device-ip-item {
            border-bottom: 0.15rem solid #eeeeee;
            margin: 0 10.4rem 0 4.4rem;

            .switch-item {
                border-bottom-style: none !important;
                margin: 0;
                width: 100%;
            }

            .device-ip-errormsg {
                width: 79%;
                margin-left: auto;
                text-align: left;
            }
        }
    }
}
</style>
